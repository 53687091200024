<template>
    <section class="sabc_tier_sports_sec">
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div>
                        <div class="sabc_tier_news_sec">
                            <div class="sabc_tier_news_row">
                                <div class="sabc_tier_news_pic loading">
                                    <!-- Shimmer effect for the image -->
                                    <div class="shimmer-image"></div>
                                </div>
                                <div class="sabc_tier_news_desc loading">
                                    <!-- Shimmer effect for the text -->
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-date"></div>
                                </div>
                            </div>
                            <div class="sabc_tier_news_row">
                                <div class="sabc_tier_news_pic loading">
                                    <!-- Shimmer effect for the image -->
                                    <div class="shimmer-image"></div>
                                </div>
                                <div class="sabc_tier_news_desc loading">
                                    <!-- Shimmer effect for the text -->
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-date"></div>
                                </div>
                            </div>
                            <div class="sabc_tier_news_row">
                                <div class="sabc_tier_news_pic loading">
                                    <!-- Shimmer effect for the image -->
                                    <div class="shimmer-image"></div>
                                </div>
                                <div class="sabc_tier_news_desc loading">
                                    <!-- Shimmer effect for the text -->
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-date"></div>
                                </div>
                            </div>
                            <div class="sabc_tier_news_row">
                                <div class="sabc_tier_news_pic loading">
                                    <!-- Shimmer effect for the image -->
                                    <div class="shimmer-image"></div>
                                </div>
                                <div class="sabc_tier_news_desc loading">
                                    <!-- Shimmer effect for the text -->
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-date"></div>
                                </div>
                            </div>
                            <div class="sabc_tier_news_row">
                                <div class="sabc_tier_news_pic loading">
                                    <!-- Shimmer effect for the image -->
                                    <div class="shimmer-image"></div>
                                </div>
                                <div class="sabc_tier_news_desc loading">
                                    <!-- Shimmer effect for the text -->
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-date"></div>
                                </div>
                            </div>
                            <div class="sabc_tier_news_row">
                                <div class="sabc_tier_news_pic loading">
                                    <!-- Shimmer effect for the image -->
                                    <div class="shimmer-image"></div>
                                </div>
                                <div class="sabc_tier_news_desc loading">
                                    <!-- Shimmer effect for the text -->
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-date"></div>
                                </div>
                            </div>
                            <div class="sabc_tier_news_row">
                                <div class="sabc_tier_news_pic loading">
                                    <!-- Shimmer effect for the image -->
                                    <div class="shimmer-image"></div>
                                </div>
                                <div class="sabc_tier_news_desc loading">
                                    <!-- Shimmer effect for the text -->
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-text"></div>
                                    <div class="shimmer-date"></div>
                                </div>
                            </div>
                            <!-- Repeat the above structure for all the news items -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'listing-shimmer',
    }
</script>
<style scope>
    .sabc_tier_news_row{display:flex;margin-bottom:20px}.loading{background:#f0f0f0;border-radius:8px;position:relative;overflow:hidden}.shimmer-image{width:100%;height:232px;background:#e0e0e0;border-radius:8px}.shimmer-date,.shimmer-text{background:#e0e0e0;border-radius:4px}.shimmer-text{width:100%;height:16px;margin:8px 0}.shimmer-date{width:120px;height:14px;margin-top:10px}@keyframes shimmer{0%{background-position:-200%}100%{background-position:200%}}.loading .shimmer-date,.loading .shimmer-image,.loading .shimmer-text{background:linear-gradient(90deg,#f0f0f0 25%,#e0e0e0 50%,#f0f0f0 75%);background-size:200% 100%;animation:1.5s linear infinite shimmer}.sabc_tier_news_pic{width:368px;height:232px}.sabc_tier_news_desc{flex-grow:1}@media (max-width:768px){.sabc_tier_news_row{gap:10px}.sabc_tier_news_pic,.shimmer-image{height:91px;width:144px}.sabc_tier_news_desc{width:calc(100% - 154px)}}
</style>